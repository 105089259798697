import { useLoaderData } from 'react-router-dom'
import PostMeta from './PostMeta'
import PostImage from './PostImage'
import MorePosts from './MorePosts'
import setDocumentHeadTags from './utils/setDocumentHeadTags'
import './assets/css/Page.scss'

function Page () {
  const { pageData, prevPosts, nextPosts, otherPosts } = useLoaderData()

  if (pageData.code) {
    throw new Error(JSON.stringify(pageData))
  }

  const doc = new DOMParser().parseFromString(pageData?.post_title, 'text/html') // eslint-disable-line no-undef
  const postTitle = doc.documentElement.textContent

  setDocumentHeadTags({
    title: postTitle,
    excerpt: pageData?.post_content,
    url: window.location.href,
    image: pageData.featured_image_src
  })

  return (
    <div className='Page'>
      <PostImage featured_image_src={pageData.featured_image_src} />

      <div className='postTitle'>
        <h1>{postTitle}</h1>

        <PostMeta {...pageData} />
      </div>

      <div className='postContent' dangerouslySetInnerHTML={{ __html: pageData?.post_content }} />

      {(otherPosts?.length || nextPosts?.length || prevPosts?.length) && <MorePosts nextPosts={nextPosts} prevPosts={prevPosts} otherPosts={otherPosts} />}
    </div>
  )
}

export default Page
