import video from './assets/images/tv_noise_glitch_1.mp4'
import './assets/css/ErrorPage.scss'

function NotFoundError () {
  return (
    <div className='ErrorPage'>
      <div className='errorMessage'>
        <h1>Page Not Found</h1>
        <p>There's nothing here.</p>
      </div>
      <div className='videoContainer'>
        <video autoPlay muted loop src={video} />
      </div>
    </div>
  )
}

export default NotFoundError
