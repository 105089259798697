import PostSummary from './PostSummary'
import './assets/css/MorePosts.scss'

function renderOtherPosts (posts, title) {
  if (posts.length) {
    return (
      <div className='MorePosts'>
        <h2><span>{title}</span></h2>

        <div className='postList'>
          {posts?.map((post) => <PostSummary key={post.id} {...post} />)}
        </div>
      </div>
    )
  }
}

function MorePosts (props) {
  if (props.prevPosts?.length || props.nextPosts?.length) {
    return (
      <>
        {renderOtherPosts(props.nextPosts, 'Up Next')}
        {renderOtherPosts(props.prevPosts, 'Previously')}
      </>
    )
  } else {
    return (
      <div className='MorePosts'>
        {renderOtherPosts(props.otherPosts, 'Continue Reading')}
      </div>
    )
  }
}

export default MorePosts
