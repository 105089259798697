import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import getSearchTerms from './utils/getSearchTerms'
import Layout from './Layout'
import Home from './Home'
import Archive from './Archive'
import Page from './Page'
import LoadingSite from './LoadingSite'
import reportWebVitals from './reportWebVitals'
import GenericError from './GenericError'
import './assets/css/index.scss'

const cmsURL = window.location.hostname === 'localhost' ? 'http://localhost/nervesandnoise/cms/' : 'https://www.nervesandnoise.com/cms/'

const router = createBrowserRouter([
  {
    path: '/',
    loader: async () => {
      return fetch(`${cmsURL}wp-json/menus/v1/menus/all-pages`)
    },
    element: <Layout />,
    children: [
      {
        index: true,
        loader: async () => {
          return fetch(`${cmsURL}wp-json/wp/v2/posts?per_page=11`)
        },
        element: <Home />,
        errorElement: <GenericError />
      },
      {
        path: '/page/:pagenum',
        loader: async ({ params }) => {
          const offset = params?.pagenum === '1' ? 0 : ((params?.pagenum - 1) * 10) + 1
          const perPage = params?.pagenum === '1' ? 11 : 10
          return fetch(`${cmsURL}wp-json/wp/v2/posts?offset=${offset}&per_page=${perPage}`)
        },
        element: <Archive />,
        errorElement: <GenericError />
      },
      {
        path: '/search',
        loader: async ({ request }) => {
          const searchParams = getSearchTerms(request.url)
          const offset = !searchParams.page ? 0 : (searchParams.page - 1) * 10
          return fetch(`${cmsURL}wp-json/wp/v2/posts?search=${encodeURIComponent(searchParams.terms)}&offset=${offset}&per_page=10`)
        },
        element: <Archive search />,
        errorElement: <GenericError />
      },
      {
        path: '/tag/:tagname',
        loader: async ({ params, request }) => {
          const tagsResponse = await fetch(`${cmsURL}wp-json/wp/v2/tags?per_page=1000`)
          const tags = await tagsResponse.json()
          const theTag = tags.find(tag => tag.slug === params?.tagname)

          if (!theTag) {
            throw new Response('Not Found', { status: 404 })
          }

          const searchParams = getSearchTerms(request.url)
          const offset = !searchParams.page ? 0 : (searchParams.page - 1) * 10

          const res = await fetch(`${cmsURL}wp-json/wp/v2/posts?tags=${theTag.id}&offset=${offset}&per_page=10`)
          const posts = await res.json()

          return Promise.resolve({
            posts,
            tag: theTag
          })
        },
        element: <Archive tag />,
        errorElement: <GenericError />
      },
      {
        path: '*',
        loader: async ({ params }) => {
          const cmsPostURL = `${cmsURL}${params['*']}`
          const res = await fetch(`${cmsURL}wp-json/getpostbyurl/v1/url/${cmsPostURL}`)
          const page = await res.json()
          if (res.status === 404 || page?.data?.status === 404 || page?.code === 'no_post_found') {
            throw new Response('Not Found', { status: 404 })
          }

          const primaryTag = page?.acf?.primary_tag || false

          const flattenedTags = page.tags_list.map(tag => tag.term_id)?.join(',')
          let otherPosts = []
          let prevPosts = []
          let nextPosts = []

          if (primaryTag) {
            const prevPostsResponse = await fetch(`${cmsURL}wp-json/wp/v2/posts?tags=${primaryTag}&before=${page.post_date}&per_page=1&order=desc`)
            const nextPostsResponse = await fetch(`${cmsURL}wp-json/wp/v2/posts?tags=${primaryTag}&after=${page.post_date}&per_page=1&order=asc`)
            prevPosts = await prevPostsResponse.json()
            nextPosts = await nextPostsResponse.json()
          }

          if (flattenedTags?.length > 0) {
            const otherPostsResponse = await fetch(`${cmsURL}wp-json/wp/v2/posts?tags=${flattenedTags}&exclude=${page.ID}&per_page=2`)
            otherPosts = await otherPostsResponse.json()
          }

          return Promise.resolve({
            pageData: page,
            otherPosts,
            prevPosts,
            nextPosts
          })
        },
        element: <Page />,
        errorElement: <GenericError />
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <LoadingSite />
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
