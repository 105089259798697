import { useEffect, useState } from 'react'

export default function useFetch (url) {
  const [data, setData] = useState({ data: null, headers: null })

  useEffect(() => {
    async function loadData () {
      const response = await fetch(url)
      if (!response.ok) {
        return {
          errorCode: 'REQUEST_FAILED',
          errorMessage: `Could not fetch data from ${url}`
        }
      }

      const headers = {}
      response.headers.forEach((value, key) => { headers[key] = value })
      const responseData = await response.json()

      setData({ data: responseData, headers })
    }

    loadData()
  }, [url])

  return data
}
