import { useRouteError } from 'react-router-dom'
import NotFoundError from './NotFoundError'
import setDocumentHeadTags from './utils/setDocumentHeadTags'
import video from './assets/images/tv_noise_glitch_1.mp4'
import './assets/css/ErrorPage.scss'

function GenericError () {
  const error = useRouteError()

  try {
    if (error.status === 404) {
      setDocumentHeadTags({
        title: 'Page Not Found',
        excerpt: 'The requested page was not found.'
      })
      return (<NotFoundError />)
    }
  } catch (err) {
    // must be normal error message string
  }

  setDocumentHeadTags({
    title: 'Something Went Wrong',
    excerpt: 'Please try reloading the page or use the menu to find something else.'
  })

  return (
    <div className='ErrorPage'>
      <div className='errorMessage'>
        <h1>Something Went Wrong</h1>
        <p>Please try reloading the page or use the menu to find something else.</p>
      </div>
      <div className='videoContainer'>
        <video autoPlay muted loop src={video} />
      </div>
    </div>
  )
}

export default GenericError
