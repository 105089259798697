import { useState, useEffect } from 'react'
import { useLoaderData, useLocation, useNavigation, Link } from 'react-router-dom'
import { ReactComponent as Logo } from './assets/images/logo.svg'
import './assets/css/TopNav.scss'

const cmsURL = window.location.hostname === 'localhost' ? 'http://localhost/nervesandnoise/cms/' : 'https://www.nervesandnoise.com/cms/'
const spaURL = window.location.hostname === 'localhost' ? 'http://localhost:3000/' : 'https://nervesandnoise.com/'

function TopNav () {
  const nav = useLoaderData()
  const [menuOpen, setMenuOpen] = useState(false)
  const [openSubmenus, setOpenSubmenus] = useState([])
  const location = useLocation()
  const navigation = useNavigation()

  useEffect(() => {
    // close the menu & submenus when navigation happens
    setMenuOpen(false)
    setOpenSubmenus([])
  }, [location])

  const mapNavItems = (items) => {
    return (
      <ol>
        {items?.map(item => {
          const submenuOpen = openSubmenus.indexOf(item.ID) > -1
          return (
            <li key={item.ID} className={`${item?.child_items ? 'hasSubmenu' : ''} ${submenuOpen ? 'submenuOpen' : ''}`}>
              <div>
                <Link to={item.url.replace(cmsURL, spaURL)}>{item.title}</Link>
                {item?.child_items &&
                  <button onClick={() => {
                    if (submenuOpen) {
                      setOpenSubmenus(openSubmenus.filter(subMenu => subMenu !== item.ID))
                    } else {
                      setOpenSubmenus([...openSubmenus, item.ID])
                    }
                  }}
                  >
                    <span className='material-symbols-outlined'>
                      {submenuOpen ? 'expand_less' : 'expand_more'}
                    </span>
                  </button>}
              </div>
              {(item?.child_items) && mapNavItems(item.child_items)}
            </li>
          )
        })}
      </ol>
    )
  }

  return (
    <>
      <div id='TopNavMenuBg' className={`${menuOpen ? 'open' : ''}`} />
      <nav id='TopNavMenu' className={`${menuOpen ? 'open' : ''}`}>{mapNavItems(nav?.items)}</nav>

      <div id='TopNav'>
        <Link to='/' className='logo'>
          <Logo />
          <div className={`loader${navigation.state === 'loading' ? ' loading' : ''}`}>
            <span className='material-symbols-outlined'>
              progress_activity
            </span>
          </div>
        </Link>

        <button
          onClick={() => {
            setMenuOpen(!menuOpen)

            if (menuOpen) {
              setOpenSubmenus([])
            }
          }}
          className={`${menuOpen ? 'open' : ''}`}
        >
          <span className='label'>Menu</span>
        </button>
      </div>
    </>
  )
}

export default TopNav
