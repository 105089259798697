import { Link } from 'react-router-dom'
import './assets/css/PostImage.scss'

const cmsURL = window.location.hostname === 'localhost' ? 'http://localhost/nervesandnoise/cms/' : 'https://www.nervesandnoise.com/cms/'
const spaURL = window.location.hostname === 'localhost' ? 'http://localhost:3000/' : 'https://nervesandnoise.com/'

function ImageContainer (imgSrc) {
  return (
    <>
      <div
        className='imageContainer'
      >
        <div
          className='image'
          style={{
            backgroundImage: imgSrc !== '' ? `url("${imgSrc}")` : 'none'
          }}
        />
      </div>
      <div className='vignette' />
      <div className='noise' />
    </>
  )
}

function PostImage (props) {
  if (props.link) {
    return (
      <Link
        to={props.link?.replace(cmsURL, spaURL)}
        className='featuredImage'
      >
        {ImageContainer(props.featured_image_src)}
      </Link>
    )
  } else {
    return (
      <div className='featuredImage'>
        {ImageContainer(props.featured_image_src)}
      </div>
    )
  }
}

export default PostImage
