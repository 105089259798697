import { useLoaderData } from 'react-router-dom'
import PostSummary from './PostSummary'
import Pagination from './Pagination'
import getSearchTerms from './utils/getSearchTerms'
import setDocumentHeadTags from './utils/setDocumentHeadTags'
import './assets/css/PostList.scss'

function Archive (props) {
  const loaderData = useLoaderData()
  let posts = []
  if (loaderData?.posts) {
    posts = loaderData.posts
  } else {
    posts = loaderData
  }

  let currentPage

  if (props.search || props.tag) {
    currentPage = 1
  } else {
    currentPage = window.location.pathname === '/' ? 1 : parseInt(window.location.pathname.split('/')[2])
  }

  const searchParams = getSearchTerms(window.location.href)

  const getHeading = () => {
    if (props.search) {
      return `Results for "${searchParams?.terms || ''}"`
    } else if (props.tag) {
      return `Posts tagged with "${loaderData?.tag?.name || ''}"`
    } else {
      return `Page ${currentPage}`
    }
  }

  let docTitle = getHeading()

  if (props.search || props.tag) {
    docTitle += ` / Page ${searchParams?.page || 1}`
  } else {
    docTitle = `Articles / ${docTitle}`
  }

  setDocumentHeadTags({
    title: docTitle,
    url: window.location.href
  })

  return (
    <>
      <div className='PostList'>
        <h2 className='articles'><span>{getHeading()}</span></h2>
        {posts?.map((post) => <PostSummary key={post.id} {...post} />)}
        {
          posts?.length === 0 &&
            <div className='noPosts'>
              <h3>No posts found.</h3>
              <p>Try searching for something else or use the menu.</p>
            </div>
        }
      </div>

      <Pagination
        search={props.search}
        searchTerms={props.search && searchParams?.terms}
        searchPageNum={(props.search || props.tag) && (searchParams?.page || 1)}
        tag={loaderData?.tag}
      />
    </>
  )
}

export default Archive
