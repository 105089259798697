import React from 'react'
import { useLoaderData } from 'react-router-dom'
import PostSummary from './PostSummary'
import Pagination from './Pagination'
import setDocumentHeadTags from './utils/setDocumentHeadTags'
import './assets/css/PostList.scss'

function Home () {
  const posts = useLoaderData()
  const firstPost = posts?.slice(0, 1)[0]
  const remainingPosts = posts?.slice(1)

  setDocumentHeadTags()

  return (
    <>
      <PostSummary key={firstPost.id} {...firstPost} latest />

      <div className='Home PostList'>
        <h2 className='articles'><span>Articles</span></h2>
        {remainingPosts?.map((post) => <PostSummary key={post.id} {...post} />)}
      </div>

      <Pagination />
    </>
  )
}

export default Home
