import { Link } from 'react-router-dom'
import PostMeta from './PostMeta'
import PostImage from './PostImage'
import './assets/css/PostSummary.scss'

const cmsURL = window.location.hostname === 'localhost' ? 'http://localhost/nervesandnoise/cms/' : 'https://www.nervesandnoise.com/cms/'
const spaURL = window.location.hostname === 'localhost' ? 'http://localhost:3000/' : 'https://nervesandnoise.com/'

function PostSummaryContent (post) {
  const doc = new DOMParser().parseFromString(post.title?.rendered, 'text/html') // eslint-disable-line no-undef
  const postTitle = doc.documentElement.textContent

  return (
    <div className='postSummaryContent'>
      {post.latest && <div className='latestBackground' />}
      {post.latest && <span className='postLabel'>The Latest &gt;&gt;</span>}

      <h3><Link to={post.link?.replace(cmsURL, spaURL)} dangerouslySetInnerHTML={{ __html: postTitle }} /></h3>

      <PostMeta {...post} />

      {!post.latest && <div className='excerpt' dangerouslySetInnerHTML={{ __html: post.excerpt?.rendered }} />}

      <div className='postReadMore'><Link to={post.link?.replace(cmsURL, spaURL)} className='button readMore'>Read More &gt;&gt;</Link></div>
    </div>
  )
}

function PostSummary (post) {
  return (
    <div className={`PostSummary ${post.latest ? 'latest' : ''}`}>
      <PostImage link={post.link} featured_image_src={post.featured_image_src} />
      {PostSummaryContent(post)}
    </div>
  )
}

export default PostSummary
