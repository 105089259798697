import React from 'react'
import { Link } from 'react-router-dom'
import useFetch from './hooks/useFetch'
import './assets/css/Pagination.scss'

const cmsURL = window.location.hostname === 'localhost' ? 'http://localhost/nervesandnoise/cms/' : 'https://www.nervesandnoise.com/cms/'

function getPageCount (props, totalPosts) {
  return (props.search || props.tag) ? Math.ceil(totalPosts / 10) : Math.ceil((totalPosts - 11) / 10) + 1
}

function buildPageURLs (props, totalPosts) {
  const pages = getPageCount(props, totalPosts)
  const pageURLs = []

  let n = 1
  while (n <= pages) {
    if (props.search) {
      let pageURL = `/search?terms=${encodeURIComponent(props.searchTerms)}`
      if (n !== 1) {
        pageURL += `&page=${n}`
      }
      pageURLs.push(pageURL)
    } else if (props.tag) {
      let pageURL = `/tag/${props.tag.slug}/`
      if (n !== 1) {
        pageURL += `?page=${n}`
      }
      pageURLs.push(pageURL)
    } else {
      pageURLs.push(n === 1 ? '/' : `/page/${n}`)
    }

    n++
  }

  return pageURLs
}

function Pagination (props) {
  let fetchURL = `${cmsURL}wp-json/wp/v2/posts?per_page=1`

  if (props.search) {
    fetchURL = `${cmsURL}wp-json/wp/v2/posts?search=${encodeURIComponent(props.searchTerms)}&per_page=1`
  } else if (props.tag) {
    fetchURL = `${cmsURL}wp-json/wp/v2/posts?tags=${props.tag.id}&per_page=1`
  }

  const response = useFetch(fetchURL) // this request is to get the total count from the headers
  const totalPosts = response?.headers?.['x-wp-total']
  const pageURLs = buildPageURLs(props, totalPosts)

  let windowMatch = window.location.pathname
  let currentPage = windowMatch === pageURLs[0] ? 1 : parseInt(windowMatch.split('/')[2])
  let prevURL = currentPage === 2 ? '/' : `/page/${currentPage - 1}`
  let nextURL = `/page/${currentPage + 1}`

  if (props.search) {
    windowMatch = window.location.pathname + window.location.search
    currentPage = parseInt(props.searchPageNum)
    prevURL = currentPage === 2 ? `/search?terms=${encodeURIComponent(props.searchTerms)}` : `/search?terms=${encodeURIComponent(props.searchTerms)}&page=${currentPage - 1}`
    nextURL = `/search?terms=${encodeURIComponent(props.searchTerms)}&page=${currentPage + 1}`
  } else if (props.tag) {
    windowMatch = window.location.pathname + window.location.search
    currentPage = parseInt(props.searchPageNum)
    prevURL = currentPage === 2 ? `/tag/${props.tag.slug}/` : `/tag/${props.tag.slug}/?page=${currentPage - 1}`
    nextURL = `/tag/${props.tag.slug}/?page=${currentPage + 1}`
  }

  return response?.headers?.['x-wp-total'] && pageURLs.length > 1 && (
    <nav className='Pagination'>
      {windowMatch !== pageURLs[0] && <Link to={prevURL} className='previousPage'>&lt;</Link>}
      {pageURLs.map((url, index) => <Link to={url} key={url} className={url === windowMatch ? 'pageNumber currentPage' : 'pageNumber'}>{index + 1}</Link>)}
      {windowMatch !== pageURLs[pageURLs.length - 1] && <Link to={nextURL} className='nextPage'>&gt;</Link>}
    </nav>
  )
}

export default Pagination
