const spaURL = window.location.hostname === 'localhost' ? 'http://localhost:3000/' : 'https://nervesandnoise.com/'

function strip (html) {
  const doc = new DOMParser().parseFromString(html, 'text/html') // eslint-disable-line no-undef
  return doc.body.textContent.trim() || ''
}

export default function setDocumentHeadTags (params = {}) {
  const { title, excerpt, image, url } = params
  const defaultTitle = 'Nerves & Noise'
  const defaultDescription = 'Project cars, photography, and other meanderings.'
  const defaultImage = '/android-chrome-512x512.png'
  const defaultURL = spaURL

  const titleToSet = title ? `${title} // ${defaultTitle}` : defaultTitle
  const descriptionToSet = excerpt || defaultDescription
  const imageToSet = image || defaultImage
  const urlToSet = url || defaultURL

  const parsedDescription = strip(descriptionToSet)

  document.title = titleToSet
  document.querySelector('meta[name="description"]').setAttribute('content', parsedDescription.substring(0, 200))
  document.querySelector('meta[property="og:title"]').setAttribute('content', titleToSet)
  document.querySelector('meta[property="og:description"]').setAttribute('content', parsedDescription.substring(0, 200))
  document.querySelector('meta[property="og:image"]').setAttribute('content', imageToSet)
  document.querySelector('meta[property="og:url"]').setAttribute('content', urlToSet)
}
