import { Link } from 'react-router-dom'

const cmsURL = window.location.hostname === 'localhost' ? 'http://localhost/nervesandnoise/cms/' : 'https://www.nervesandnoise.com/cms/'
const spaURL = window.location.hostname === 'localhost' ? 'http://localhost:3000/' : 'https://nervesandnoise.com/'

function formatDate (gmtDate) {
  const d = new Date(`${gmtDate}.000Z`)
  const f = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })
  const dateParts = f.formatToParts(d)
  const dateObj = {}
  dateParts.forEach(part => {
    dateObj[part.type] = part.value
  })
  return `${dateObj.day} ${dateObj.month} ${dateObj.year} // ${dateObj.hour}:${dateObj.minute}:${dateObj.second}`
}

function PostMeta (props) {
  return (
    <div className='postMeta'>
      <span className='date'>{formatDate(props.date_gmt || props.post_date_gmt)}</span>
      {props.tags_list?.length > 0 && <span className='tags'>{props.tags_list?.map(tag => <Link key={tag.term_id} to={tag.link?.replace(cmsURL, spaURL)}>{tag.name}</Link>)}</span>}
    </div>
  )
}

export default PostMeta
