import { ReactComponent as Logo } from './assets/images/logo.svg'
import './assets/css/LoadingSite.scss'

function NotFoundError () {
  return (
    <div id='LoadingSite'>
      <Logo className='logo' />
      <h2>... Loading Content ...</h2>
    </div>
  )
}

export default NotFoundError
