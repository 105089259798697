import { Outlet, ScrollRestoration } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import TopNav from './TopNav'
import Footer from './Footer'
import GenericError from './GenericError'

function Layout () {
  document.getElementById('LoadingSite')?.remove()

  return (
    <div id='Layout'>
      <div id='PageBody'>
        <TopNav />
        <ErrorBoundary FallbackComponent={GenericError}>
          <Outlet />
        </ErrorBoundary>
      </div>
      <Footer />
      <ScrollRestoration />
    </div>
  )
}

export default Layout
