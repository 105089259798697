import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './assets/css/Footer.scss'

function Footer () {
  const date = new Date()
  const [searchTerm, setSearchTerm] = useState('')
  const navigate = useNavigate()

  const handleSearchSubmit = (event) => {
    event.preventDefault()
    navigate(`/search?terms=${encodeURIComponent(searchTerm)}`)
  }

  return (
    <div className='Footer'>
      <div className='footerContent'>
        <form onSubmit={handleSearchSubmit}>
          <input type='text' name='search' placeholder='Search' onChange={event => setSearchTerm(event.target.value)} />
          <button type='submit'>Submit</button>
        </form>

        <form
          action='https://nervesandnoise.us21.list-manage.com/subscribe/post?u=9c427a79a0d6fb2db54fad724&amp;id=57692d0e9b&amp;f_id=00ceeae6f0'
          method='post'
          id='mc-embedded-subscribe-form'
          name='mc-embedded-subscribe-form'
          className='validate'
          target='_blank'
          noValidate=''
        >
          <h3>Subscribe for Updates</h3>
          <p>We'll send you an email when there's a new article.</p>

          <fieldset>
            <input type='email' name='EMAIL' className='required email' id='mce-EMAIL' required='' placeholder='Email Address' autoComplete='email' />

            <div aria-hidden='true' style={{ position: 'absolute', left: '-5000px' }}>
              <input type='text' name='b_9c427a79a0d6fb2db54fad724_57692d0e9b' tabIndex='-1' />
            </div>

            <button type='submit' name='subscribe' id='mc-embedded-subscribe' className='button'>Subscribe</button>
          </fieldset>
        </form>

        <span>&copy; {date.getFullYear()} Nerves &amp; Noise</span>
      </div>
    </div>
  )
}

export default Footer
